<template>
  <div class="image-trend-map">
    <div>
      <ai-breadcrumb />
      <div class="new-button">
        <guide class="guide-button" />
      </div>
    </div>
    <div class="search-content">
      <el-form class="search-form" :inline="true" size="small" @submit.native.prevent>
        <el-form-item class="search-form-gutter" :label="$t('analysis.platform')">
          <el-select style="width: 88px" v-model="currentPlatform" :disabled="true" @change="changePlatform" filterable>
            <el-option v-for="item in platformList" :key="item.key" :label="item.value" :value="item.key"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.date')">
          <el-select style="width: 167px" v-model="date" :multiple-limit="9" filterable multiple collapse-tags>
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 167px" v-model="compareDate" :multiple-limit="9" filterable multiple collapse-tags>
            <el-option v-for="item in dateList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter" :label="$t('analysis.group')">
          <el-select style="width: 120px" v-model="type">
            <el-option v-for="item in typeList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
          <label class="date-vs">VS</label>
          <el-select style="width: 120px" v-model="compareType">
            <el-option v-for="item in typeList" :key="item" :label="item" :value="item"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button :disabled="!date ||
        date.length === 0 ||
        !compareDate ||
        compareDate.length === 0 ||
        !type ||
        !compareType
        " size="small" :circle="true" type="primary" @click="fetchTab" icon="el-icon-search"></el-button>
        </el-form-item>
        <el-form-item class="search-form-gutter">
          <el-button :disabled="!date ||
        date.length === 0 ||
        !compareDate ||
        compareDate.length === 0 ||
        !type ||
        !compareType
        " class="excel-dl-btn" size="small" :circle="true" @click="handleDownload" icon="el-icon-download"></el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="itemList.length > 0" class="page-tab" v-loading="dataLoading">
      <el-tabs v-model="activeTab" type="card" @tab-click="fetchData">
        <el-tab-pane v-for="item in itemList" :label="item" :name="item" :key="item"></el-tab-pane>
      </el-tabs>
      <el-table class="tab-table" :data="tableData">
        <el-table-column :label="$t('imageTrendMap.specificItem')" prop="specificItem" min-width="70"></el-table-column>
        <el-table-column :label="type">
          <el-table-column :label="$t('imageTrendMap.volume') + ' %'" min-width="60">
            <template slot-scope="scope">
              {{ Math.round(scope.row.group.volume * 100) / 100 }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('imageTrendMap.trend')" min-width="60">
            <template slot-scope="scope">
              {{ Math.round(scope.row.group.trend * 100) / 100 }}
            </template></el-table-column>
          <el-table-column :label="$t('imageTrendMap.quadrant')" min-width="40" prop="group.quadrant"></el-table-column>
        </el-table-column>
        <el-table-column :label="compareType">
          <el-table-column :label="$t('imageTrendMap.volume') + ' %'" min-width="60">
            <template slot-scope="scope">
              {{ Math.round(scope.row.compareGroup.volume * 100) / 100 }}
            </template></el-table-column>
          <el-table-column :label="$t('imageTrendMap.trend')" min-width="60"><template slot-scope="scope">
              {{ Math.round(scope.row.compareGroup.trend * 100) / 100 }}
            </template></el-table-column>
          <el-table-column :label="$t('imageTrendMap.quadrant')" min-width="40"
            prop="compareGroup.quadrant"></el-table-column>
        </el-table-column>
        <el-table-column :label="$t('imageTrendMap.advice')">
          <el-table-column :label="$t('imageTrendMap.overall')" width="100">
            <template slot-scope="scope">
              <el-image v-if="scope.row.suggestionImg" :src="suggestionImage(scope.row)" fit="contain">
              </el-image>
            </template>
          </el-table-column>
          <el-table-column :label="$t('imageTrendMap.detail')" min-width="120" prop="suggestion"></el-table-column>
        </el-table-column>
        <el-table-column :label="$t('common.image')">
          <el-table-column :label="type" width="100">
            <template slot-scope="scope">
              <el-image v-if="scope.row.group.images && scope.row.group.images.length > 0
        " :size="80" fit="contain" :src="scope.row.group.images[0] | imageThumbnailUrl(true)"
                :preview-src-list="[scope.row.group.images]"></el-image>
            </template>
          </el-table-column>
          <el-table-column :label="compareType" width="100">
            <template slot-scope="scope">
              <el-image v-if="scope.row.compareGroup.images &&
        scope.row.compareGroup.images.length > 0
        " :size="80" fit="contain" :src="scope.row.compareGroup.images[0] | imageThumbnailUrl(true)
        " :preview-src-list="[scope.row.compareGroup.images]"></el-image>
            </template>
          </el-table-column>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { downloadFileAuth } from "@/utils/download-file";
import { ossRoot } from "@/utils/server";
import { aiApiRoot } from "@/utils/server";
import { getToken } from "@/utils/auth";
import {
  fetchRecords,
  fetchUserBrandType,
  fetchImageTrendMap,
  fetchImageTrendMapItems,
} from "@/api/analysis";
import AiBreadcrumb from "@/components/AiBreadcrumb";
import Guide from "@/components/Guide";
export default {
  components: {
    AiBreadcrumb,
    Guide,
  },
  data() {
    return {
      currentPlatform: "tmall",
      date: [],
      compareDate: [],
      type: null,
      compareType: null,
      platformList: [
        { key: "tmall", value: "TMall" },
        { key: "tiktok", value: "Tiktok" },
      ],
      keyword: "",
      tableData: [],
      dateList: [],
      typeList: [],
      itemList: [],
      activeTab: null,
      dataLoading: false,
      firstLoad: false,
    };
  },
  mounted() {
    this.firstLoad = false;
    this.fetchDateList();
    this.fetchTypeList();
  },
  methods: {
    changePlatform() {
      this.fetchDateList();
      this.fetchTypeList();
    },
    fetchDateList() {
      this.date = [];
      this.compareDate = [];
      this.dateList = [];
      fetchRecords({
        type: "sales",
        period: "week",
        platform: this.currentPlatform,
        limit: -1,
      }).then((response) => {
        if (response.success) {
          response.result &&
            response.result.items &&
            response.result.items.forEach((e) => {
              this.dateList.push({
                value: e.date,
                label: e.linkDate,
              });
            });
          if (this.dateList.length > 0) {
            this.date = [this.dateList[0].value];
            if (this.dateList.length > 1) {
              this.compareDate = [this.dateList[1].value];
            } else {
              this.compareDate = [this.dateList[0].value];
            }
          }
          if (!this.firstLoad) {
            this.fetchTab();
          }
        }
      });
    },
    fetchTypeList() {
      this.type = null;
      this.compareType = null;
      this.typeList = [];
      fetchUserBrandType().then((response) => {
        if (response.success) {
          this.typeList = response.result.items || [];
          if (this.typeList.length > 0) {
            this.type = this.typeList[0];
            if (this.typeList.length > 1) {
              this.compareType = this.typeList[1];
            } else {
              this.compareType = this.typeList[0];
            }
          }
          if (!this.firstLoad) {
            this.fetchTab();
          }
        }
      });
    },
    fetchTab() {
      if (
        !this.date ||
        this.date.length === 0 ||
        !this.compareDate ||
        this.compareDate.length === 0 ||
        !this.type ||
        !this.compareType
      ) {
        return;
      }
      this.firstLoad = true;
      this.itemList = [];
      this.tableData = [];
      this.dataLoading = true;
      fetchImageTrendMapItems({
        date: this.date.join(","),
        compareDate: this.compareDate.join(","),
        type: this.type,
        compareType: this.compareType,
      })
        .then((response) => {
          this.dataLoading = false;
          if (response.success) {
            this.itemList = response.result || [];
            if (this.itemList.length > 0) {
              this.activeTab = this.itemList[0];
              this.fetchData();
            }
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    fetchData() {
      this.dataLoading = true;
      this.tableData = [];
      fetchImageTrendMap({
        date: this.date.join(","),
        compareDate: this.compareDate.join(","),
        type: this.type,
        compareType: this.compareType,
        item: this.activeTab,
      })
        .then((response) => {
          this.dataLoading = false;
          if (response.success) {
            this.tableData = response.result;
          }
        })
        .catch(() => {
          this.dataLoading = false;
        });
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: this.$t("common.preparingData"),
      });
      downloadFileAuth(
        aiApiRoot +
        "/v1/analysis/image-trend-map/export" +
        "?" +
        new URLSearchParams({
          date: this.date.join(","),
          compareDate: this.compareDate.join(","),
          type: this.type,
          compareType: this.compareType,
        }).toString(),
        "image-trend-map-" +
        this.date.join(",") +
        "-" +
        this.compareDate.join(",") +
        "-" +
        this.type +
        "-" +
        this.compareType +
        ".xlsx",
        getToken(),
        () => {
          loading.close();
        }
      );
    },
    suggestionImage(row) {
      let env = process.env.VUE_APP_ENV;
      if (!env) {
        env = "staging";
      }
      if (env === "production.kr") { env = "production" }
      let url =
        ossRoot + "/" + env + "/common" + row.suggestionImg.substring(8);
      return url;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-trend-map {
  .new-button {
    text-align: right;
    width: 100%;
    margin: 10px 0;
  }

  .search-content {
    background-color: #fff;
    padding: 10px 0 0 10px;
    margin-bottom: 10px;

    .el-form-item {
      margin-bottom: 10px;
    }

    .search-form {
      width: auto;

      .search-form-gutter {
        margin-right: 10px;
      }
    }

    .date-vs {
      vertical-align: middle;
      font-size: 14px;
      color: #606266;
      margin-left: 12px;
      margin-right: 12px;
    }
  }

  .page-tab {
    background-color: white;
    overflow: hidden;

    /deep/ .el-tabs__header {
      padding-top: 10px;
      padding-left: 10px;
      margin-bottom: 0;
    }

    /deep/ .el-tabs__item {
      height: 34px;
      line-height: 34px;
    }

    .tab-table {
      margin-top: 10px;
      border-left: none;
      border-collapse: collapse;

      /deep/ .el-table__header-wrapper {
        border-right: 1px #f5f7fa solid;
        border-collapse: collapse;
      }

      /deep/ tr td:last-child {
        border-right: none;
      }
    }

    /deep/ .el-table--group::after {
      width: 0px;
    }

    /deep/ .el-table--border::after {
      width: 0px;
    }
  }
}
</style>